<template>
  <div>
    <Pos content="Tweet" />
    <div class="detail">
      <div class="main">
        <div class="base">
          <img
            @click="
              (e) => goAccount(e, detail.user.user_name, detail.user.user_id)
            "
            class="avatar"
            src="https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png"
          />
          <p class="title" v-if="!!Object.keys(this.detail).length">
            <span class="name">{{
              this.detail.user.user_name || "React"
            }}</span>
            <span class="account">{{
              this.detail.user.user_email || "@reactjs"
            }}</span>
          </p>
        </div>

        <div>
          <p class="content" v-html="this.detail.card_content"></p>

          <!-- 仅展示 -->
          <ul class="media-list" v-if="this.detail.card_media">
            <li
              v-for="item in JSON.parse(this.detail.card_media)"
              :key="item.url"
              @click="(e) => handlePictureCardPreview(e, item)"
            >
              <div class="zoom-box">
                <i class="el-icon-zoom-in"></i>
              </div>

              <img
                v-if="isImage(item)"
                :src="item.url"
                style="width: 100%; height: 100%; object-fit: cover"
              />

              <video
                v-if="isVideo(item)"
                style="width: 100%; height: 100%; object-fit: cover"
                :src="item.url"
                autoplay
                muted
              ></video>
            </li>

            <li
              :style="{
                visibility: 'hidden',
              }"
              v-for="placeholder in placeholderCount"
              :key="placeholder"
            ></li>
          </ul>

          <div class="btn-group">
            <a href="javascript:void(0)">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path
                    d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                  ></path>
                </g>
              </svg>
              <span>646</span>
            </a>

            <a href="javascript:void(0)">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path
                    d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"
                  ></path>
                </g>
              </svg>
              <span>218</span>
            </a>

            <Like
              :count="this.detail.card_like"
              :is-like="this.detail.is_like"
              :card-id="this.detail.card_id"
              source-key="detail"
              @done="likeComplete"
            />

            <a href="javascript:void(0)">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path
                    d="M17.53 7.47l-5-5c-.293-.293-.768-.293-1.06 0l-5 5c-.294.293-.294.768 0 1.06s.767.294 1.06 0l3.72-3.72V15c0 .414.336.75.75.75s.75-.336.75-.75V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22c.293-.293.293-.767 0-1.06z"
                  ></path>
                  <path
                    d="M19.708 21.944H4.292C3.028 21.944 2 20.916 2 19.652V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 .437.355.792.792.792h15.416c.437 0 .792-.355.792-.792V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 1.264-1.028 2.292-2.292 2.292z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- 预览 -->
    <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose">
      <img width="100%" :src="dialogImageUrl" v-if="dialogImageUrl" />
      <video
        style="width: 100%"
        autoplay
        controls
        muted
        v-if="dialogVideoUrl"
        :src="dialogVideoUrl"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { cardInfoById } from "@/api/card";
import Pos from "@/components/Pos";
import Like from "@/components/Like";
import like from "@/mixins/like";
import account from "@/mixins/account";
export default {
  name: "detail",
  components: {
    Pos,
    Like,
  },
  mixins: [like, account],
  data() {
    return {
      dialogVisible: false,
      dialogVideoUrl: "",
      dialogImageUrl: "",
      detail: {},
    };
  },
  async created() {
    const { data } = await cardInfoById({ card_id: this.$route.params.id });
    this.detail = data;
  },
  computed: {
    // 图片补位
    placeholderCount() {
      const length = JSON.parse(this.detail.card_media).length % 3;

      if (length === 0) {
        return 0;
      }

      return 3 - length;
    },
  },
  methods: {
    isImage(file) {
      return file.type === "image" || file.raw?.type?.includes("image/");
    },
    isVideo(file) {
      return file.type === "video" || file.raw?.type?.includes("video/");
    },
    // 预览层关闭前
    beforeClose(close) {
      this.dialogImageUrl = "";
      this.dialogVideoUrl = "";
      close();
    },
    // 预览图/视频
    handlePictureCardPreview(e, file) {
      e.stopPropagation();
      this.dialogVisible = true;

      if (this.isImage(file)) {
        this.dialogImageUrl = file.url;
      } else if (this.isVideo(file)) {
        this.dialogVideoUrl = file.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 12px 16px;
  border-bottom: 1px solid #eff3f4;
  border-top: 1px solid #eff3f4;

  .main {
    .base {
      display: flex;
      align-items: center;
      margin: 15px 0;

      img.avatar {
        display: block;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
        border: 1px solid rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
    }

    & > div {
      width: 100%;

      p.title {
        span {
          display: block;
          font-size: 15px;
          line-height: 18px;

          &.name {
            font-weight: 700;
            color: rgb(15, 20, 25);
          }

          &.account,
          &.date {
            color: rgb(83, 100, 113);
            font-weight: 400;
          }
        }
      }

      p.content {
        font-weight: 400;
        font-size: 23px;
        color: rgb(15, 20, 25);
        line-height: 28px;
        margin-bottom: 5px;
      }

      ul.media-list {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 190px;
          height: 198px;
          border-radius: 6px;
          background-color: rgb(255, 255, 255);
          box-sizing: border-box;
          margin: 0px 14px 14px 0px;
          overflow: hidden;
          position: relative;

          .zoom-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            z-index: 99;

            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.5);
              opacity: 1;
            }

            i {
              font-size: 24px;
              color: #fff;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .btn-group {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 12px 0;

        a {
          display: flex;
          text-decoration: none;
          color: rgb(83, 100, 113);

          svg {
            width: 18px;
            height: 18px;
            fill: currentcolor;
            color: rgb(83, 100, 113);
          }

          span {
            display: block;
            font-size: 13px;
            line-height: 16px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>>